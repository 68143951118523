<template>
  <div class="row">
    <!-- <a-modal
      v-model="modal"
      title="Upload Bulk Pasien"
      :footer="null"
      @ok="handleOk"
      :destroyOnClose="true"
    >
      <a-radio-group v-model="type" buttonStyle="solid" class="d-flex mb-3">
        <a-radio class="my-1" value="tagrisso">Tagrisso</a-radio>
        <a-radio class="my-1" value="faslodex">Faslodex</a-radio>
        <a-radio class="my-1" value="lynparza">Lynparza</a-radio>
        <a-radio class="my-1" value="imfinzi">Imfinzi</a-radio>
        <a-radio class="my-1" value="calquence">Calquence</a-radio>
      </a-radio-group>
      <a-upload
        class="mr-1"
        @change="handleChange"
        name="upload"
        :before-upload="beforeUpload"
        :max-count="1"
        :multiple="false"
        :remove="handleRemove"
      >
        <a-button :disabled="!!file">
          <a-icon type="upload"></a-icon>
          Unggah Raw
        </a-button>
      </a-upload>
      <a-button
        type="primary"
        :disabled="!file"
        :loading="uploading"
        style="margin-top: 16px"
        @click="handleUpload"
      >
        {{ uploading ? 'Uploading' : 'Start Upload' }}
      </a-button>
    </a-modal> -->
    <!-- <a-modal
      v-model="modal"
      title="Tambah Data Medis Pasien"
      :footer="null"
      @ok="handleOk"
      :destroyOnClose="true"
    >
      <div>Masukkan No. RM Pasien</div>
      <br>
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item label="No. RM" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-input placeholder="No. RM" v-decorator="['no_rm', { rules: [{ required: true, message: 'No. RM wajib diisi!' }] }]" />
        </a-form-item>
        <button type="submit" class="btn btn-success px-5">
          Tambah Data Medis
        </button>
      </a-form>
    </a-modal> -->
    <div class="col-xl-12 col-lg-12">
      <div>
        <div class="">
          <!-- <div class="card-header">
            <a-button type="primary" @click="goToNew">
              + Tambah Pasien
            </a-button>
            {{ ' ' }}
            <a-button type="primary" @click="goToNewMedRecord">
              + Tambah Rekam Medis
            </a-button>
          </div> -->
          <div class="card-body">
              <div class="row" style="background-color: #F3FDFF;">
                <div class="col-3 container">
                  <img 
                  src="resources/images/medisync-big.svg"
                  alt="Medisync"
                  height="50px"
                  >
                </div>
                <div class="col-6 header-text container">Daftar Pasien</div>
                <div class="col-3 container">
                  {{ this.name }}
                </div>
              </div>
              
          </div>
          <div class="card-body">
            <!-- <tabel-pasien />
            <div>ini yang baru</div> -->
            <tabel-pasien-baru />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import TabelPasien from '@/components/tables/pasien-new'
import TabelPasienBaru from '@/components/tables/pasien-new-details'
import router from '@/router'
import { BASE_URL } from '@/config'
import apiClient from '@/services/axios'
import { notification } from 'ant-design-vue'
import { create } from '@/services/axios/api/medicalRecord'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 5 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}

// import { message } from 'ant-design-vue'
// import {
//   // SearchOutlined,
//   // PlusOutlined,
//   CloudUploadOutlined,
//   // CloudDownloadOutlined,
//   // ExclamationCircleOutlined,
//   // CloseSquareOutlined,
//   // InfoCircleOutlined,
// } from '@ant-design/icons-vue'
export default {
  components: {
    // TabelPasien,
    TabelPasienBaru,
    // CloudUploadOutlined,
  },
  data: function () {
    return {
      isAdmin: false,
      name: null,
      modal: false,
      // action: `${BASE_URL}/patient/dashboard/upload?type=${type}`,
      type: 'tagrisso',
      base_url: BASE_URL,
      file: null,
      uploading: false,
      labelCol,
      wrapperCol,
      form: this.$form.createForm(this),
    }
  },
  created() {
    this.getRole()
    this.getName()
  },
  methods: {
    getRole() {
      const role = window.localStorage.getItem('role')
      // console.log(role)
      if (role === 'admin') {
        this.isAdmin = true
      }
    },
    getName() {
      const name = window.localStorage.getItem('name')
      this.name = name
    },
    goToNew() {
      router.push('/pasien/tambah')
    },
    goToNewMedRecord(){
      this.modal = true
    },
    handleSubmit(e){
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          try{
            console.log('Received values of form: ', values)
            const response = await create(values)
            console.log(response.data.message)
            const message = response.data.message
            const id = message.split(' ')[6]
            console.log("id", id)
            if (response.status === 201){
              notification.success({
                message: 'Sukses',
                description: 'Data medis pasien berhasil ditambah',
              })
              router.push(`/formulir/${id}`)
            }
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
    goToUpdate() {
      router.push('/pasien/update')
    },
    goToUpdateProgram() {
      router.push('/pasien/program/tambah')
    },
    goToPatientTarget() {
      router.push('/target-pasien')
    },
    modalUpload() {
      this.modal = true
      // const DoT = 'DOT Total\n (Redemption Date)'
      // const DoTPersentage = 'DOT (%) Update'
      const maret = 'Maret 2019'
      const a = {
        No: '1',
        'ID Pasien': '2019-MLG-001',
        'Tgl Pendaftaran':
          'Sun Mar 03 2019 23:59:48 GMT+0700 (Western Indonesia Time)',
        'Kota/RS': 'Malang',
        Dokter: 'dr. Wisnu Broto SpPD. B(K)Onk',
        Specialist: 'Onco Surgeon',
        Apotek: 'Sejati Malang',
        'Rumah Sakit': 'RSUD dr Saiful Anwar',
        'enrolled in month': 'Mar 19',
        Status: 'Stop',
        'Terminate month of Program': 'June 19',
        Causal: 'Passed Away',
        'DOT Total\n (Redemption Date)': '3',
        'DOT Total': '3',
        'DOT (%) Update': '100',
        'Maret 2019': '13, 27 Mar 19',
        'April 2019':
          'Thu Apr 25 2019 23:59:48 GMT+0700 (Western Indonesia Time)',
        'Mei 2019':
          'Thu May 23 2019 23:59:48 GMT+0700 (Western Indonesia Time)',
      }
      console.log(a[maret].split(','), '<<<<')
    },
    handleOk() {
      this.modal = false
    },
    handleChange(info) {
      console.log(info.file)
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        console.log(`${info.file.name} file uploaded successfully`)
        this.file = ''
      } else if (info.file.status === 'error') {
        console.log(`${info.file.name} file upload failed.`)
      } else if (info.file.status === 'removed') {
        console.log(`${info.file.name} file upload removed.`)
        this.file = ''
      }
    },
    beforeUpload(fileUpload) {
      this.file = fileUpload
      return false
    },
    async handleUpload() {
      try {
        const formData = new FormData()
        formData.append('upload', this.file)
        this.uploading = true
        console.log(formData, '<<<<<', this.file)

        // You can use any AJAX library you like
        // apiClient.post('/patient/dashboardCreate', data, {})
        const res = await apiClient.post(
          `/patient/dashboard/upload?type=${this.type}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        this.uploading = false
        if (res.status === 200) {
          notification.success({
            message: 'Success',
            description: 'Permintaan bulk data selesai',
            duration: 5,
          })
          this.modal = false
          this.handleChange({ file: { status: 'removed' } })
          this.handleRemove()
        }
        console.log(res, '<<<<')
      } catch (error) {
        this.uploading = false
      }

      // apiClient('https://www.mocky.io/v2/5cc8019d300000980a055e76', {
      //   method: 'post',
      //   data: formData,
      // })
      //   .then(() => {
      //     fileList.value = []
      //     uploading.value = false
      //     message.success('upload successfully.')
      //   })
      //   .catch(() => {
      //     uploading.value = false
      //     message.error('upload failed.')
      //   })
    },
    handleRemove() {
      this.file = null
      return true
    },
  },
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}
.header-text{
    font-size: 18px;
    text-align: center;
    color: #428A94;
    font-weight: bold;
}

</style>